<template>
	<main-container>
		<div class="d-flex flex-row align-items-center flex-wrap">
			<form-item label="语言">
				<el-select v-model="form.mode.name" size="mini">
					<el-option label="Python" value="python"></el-option>
					<el-option label="Php" value="php"></el-option>
				</el-select>
			</form-item>
			<form-item label="模板">
				<el-select v-model="form.theme" size="mini">
					<el-option label="默认" value=""></el-option>
					<el-option label="night" value="night"></el-option>
				</el-select>
			</form-item>
			<form-item>
				<el-button type="primary" size="mini" @click="applyOptions">应用</el-button>
			</form-item>
		</div>
		<textarea ref="mycode" v-model="code" class="w-100"></textarea>
	</main-container>
</template>

<script>
	import "codemirror/theme/night.css"
	import "codemirror/lib/codemirror.css"
	import "codemirror/addon/hint/show-hint.css"
	import CodeMirror from 'codemirror/lib/codemirror'
	import 'codemirror/addon/edit/matchbrackets'
	import 'codemirror/addon/edit/closebrackets'
	import 'codemirror/addon/hint/show-hint'
	import 'codemirror/addon/selection/active-line'
	import 'codemirror/addon/selection/mark-selection'
	import 'codemirror/addon/selection/selection-pointer'
	import 'codemirror/mode/python/python'
	import 'codemirror/mode/php/php'
	export default {
		data() {
			return {
				form: {
					mode: {
						name: 'python',
					},
					theme: ''
				},
				code: '#粘贴代码...\n',
				editor: undefined
			}
		},
		mounted() {
			this.editor = CodeMirror.fromTextArea(this.$refs.mycode, {
				mode: {
					name: 'python'
				},
				indentWithTabs: true,
				smartIndent: true,
				lineNumbers: true,
				lineWrapping: true,
				matchBrackets: true,
				autoCloseBrackets: true,
				theme: ''
			})
			this.editor.on('keypress', (editor, keyboardEvent) => {
				editor.showHint()
			})
		},
		methods: {
			applyOptions() {
				Object.keys(this.form).forEach(key => {
					const value = this.form[key]
					this.editor.setOption(key, value)
				})
			}
		}
	}
</script>

<style>

</style>
